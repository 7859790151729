declare global {
  interface Window {
    ga?: any;
    gaplugins?: any;
    gtag?: any;
    dataLayer?: any;
    TradePendingReloadSuperlatives?: boolean;
    tradepending_location?: string;
    DDC?: {
      API?: any;
    };
    jQuery?: {};
    TradePendingPlugin?: any;
    attachEvent?: (name: string, cb: any) => void;
    digitalData?: any;

    Promise: typeof Promise;

    tradependingSetup?: () => void;
    tradendingLaunchSnapOverlay?: (vehicle: any) => void;
    tradependingLaunchSnapOverlay?: (vehicle: any) => void;
    tradependingConversionEvent?: (event: string) => void;
    tradependingReportRun?: (vehicle: any, pricing: any) => void;
    tradependingVehicleSelected?: (vehicle: any) => void;
    tradependingPaymentsStep?: (product: string, step: string) => void;
    _satellite?: {
      track: (event: string) => void;
    };
    AutoLeadStarEvent?: (num: number, event: string, data: any) => void;
    trafficscore?: any;
    traffic_score_data?: any;
    SMCX?: any;
    _pxam?: any[];
    sd?: (event: string, data?: any) => void;
    requested_location?: string;
    $ae?: any;
    utag?: {
      data: {
        tealium_visitor_id: string;
      };
    };
  }
}

enum IconSet {
  flat = "flat",
  colorful = "colorful",
  flatCircle = "flat_circle",
  fontAwesome = "font_awesome",
}

export type DdcCtaPluginConfig = {
  type: string;
  target: string;
  onclick: () => void;
  text: {};
  style: string;
  classes?: string;
  imgClasses?: string;
  imgSrc?: string;
  imgAlt?: string;
};

export enum ExtractedItem {
  VIN,
  STOCK_NUMBER,
  PRICE,
}

export enum VehicleCondition {
  USED = 1,
  NEW,
  CERTIFIED,
  UNKNOWN = 999,
}

export enum CacheItemType {
  SUPERLATIVE,
  VEHICLE_CONDITION,
  STOCK_NUMBER_VIN,
  AUTOAPR_VIN_CONDITION,
}

type Superlative = {
  name: string;
  icon: string;
  moreDetails: {
    message: string;
    banner_message: string;
    banner_value: string;
    banner_description: string;
    short_text: string;
    more_info_message: string;
    title: string;
    baggage: {};
    superlative: string;
  };
  iconData: {
    superlative: string;
    useSvg: boolean;
    iconSet: string;
    icon_element: string;
    superlative_icon_url: string;
  };
  show_as_sidecar?: boolean;
  title?: string; // only if show_as_sidecar = true
};

type AutoBioCell = {
  details: {
    header: string;
    body: string;
    icon: string;
  };
};

export type SuperlativeLookup = {
  dealerId?: string;
  vin: string;
  fetchedVin?: string;
  placeholder?: string;
  superlative: string;
  superlative_id: number;
  icon_element: string;
  details_superlative: Superlative[];
  details: {
    message: string;
    banner_message: string;
    banner_value: string;
    banner_description: string;
    more_info_message: string;
    title: string;
    baggage: {};
    superlative: string;
    superlative_icon_url: string;
    icon_svg: boolean;
    icon_element: string;
    font_awesome: {
      icon: string;
    };
    manager_special?: boolean;
  };
  first_three_other_superlatives: [Superlative, Superlative, Superlative];
  autobio_cells: AutoBioCell[];
  autobio_first_3_cells: [AutoBioCell, AutoBioCell, AutoBioCell];
  autobio_second_3_cells: [AutoBioCell, AutoBioCell, AutoBioCell];
};

type SuperlativeLookupResponse = {
  result: SuperlativeLookup[];
  template_html: string;
  authorized?: boolean | string;
};

// Runtime model of a CTA plugin
export type CtaPlugin = {
  cta_button_type: string;
  cta_insert_selector: string;
  cta_insert_method: InsertMethod;
  cta_vin_selector: string;
  cta_stock_number_selector: string;
  cta_price_selector: string;
  cta_html_code: string;
  cta_button_border_style: string;
  cta_button_border_radius: string;
  cta_button_text_color: string;
  cta_button_color: string;
  cta_button_class: string;
  cta_intent: string;
  cta_button_text: string;
  is_autoapr: boolean;
  use_condition_check: (vehicle: VehicleCondition) => boolean;
  cta_unique_class: string;
  ga_category_name: string;
  url_constructor: (vin: string) => string;
  intermediate_url_constructor?: (vin: string) => string;
  ctas_enabled?: Set<string>;
  price_selector_disabled_new: boolean;
  price_selector_disabled_used: boolean;
  use_stock_numbers: boolean;
  untie_stock_number_from_vin: boolean;
  stock_numbers_pattern: string;
  use_custom_vin_pattern: boolean;
  custom_vin_pattern: string;
  listing_codes_dealer_regex?: string;
  remove_ctas_for_listing_codes?: boolean;
  template_key: string;
  use_ddc_api_append: boolean;
  ddc_price_override: string;
  ddc_year_condition_override: boolean;
  analytics: { cta: string };
  vin?: string;
  stock_number?: string;
  use_ddc_api?: boolean;
  use_cta?: boolean;
  button_selector?: string;
  vehicle_condition?: { condition?: string };
  location?: string;
  ymm_selector?: string;
};

interface DdcEventMap {
  "vehicle-data-updated-v1": {
    payload: {
      vehicleData: DdcVehicle[];
    };
  };
  "vehicle-shown-v1": {
    payload: DdcVehicle;
  };
  "page-load-v1": {
    payload: {
      accountId: string;
      siteId: string;
      defaultDomain: string;
      indexPage: boolean;
      searchPage: boolean;
      detailPage: boolean;
      franchises: string[];
      design: any;
      pageName: string;
      layoutType: string;
      locale: string;
    };
  };
}
type DdcDealership = {
  dealershipName: string;
  dealershipAddress1: string;
  dealershipAddress2: string;
  dealershipCity: string;
  dealershipCodes: any;
  dealershipFranchises: string[];
  dealershipPostalCode: string;
  dealershipStateProvince: string;
  dealershipCountry: string;
};

type DdcVehicle = {
  vin: string;
  accountId: string;
  inventoryType: "new" | "used";
  year: number;
  make: string;
  model: string;
  trim: string;
  modelCode: string;
};
export type DdcApi = {
  insert: (selector: string, callback: (elem: HTMLElement, meta: DdcVehicle) => void) => void;
  insertOnce: (selector: string, callback: (elem: HTMLElement, meta: DdcVehicle) => void) => void;
  insertCallToActionOnce: (type: string, intent: string, callback: (meta: DdcVehicle) => void) => void;
  append: (targetEl: HTMLElement, appendEl: HTMLElement) => void;
  subscribe<K extends keyof DdcEventMap>(event: K, callback: (data: DdcEventMap[K]) => void): void;
  utils: {
    getVehicleData: () => Promise<DdcVehicle[]>;
    getDealerData: () => Promise<DdcDealership>;
  };
};
